import React, { Component, Fragment, PureComponent  } from 'react';
import { Button, Tooltip, GridList, MenuItem, Icon } from '@material-ui/core';
import Slider from '@material-ui/lab/Slider';
import { DatePicker, InlineDatePicker } from 'material-ui-pickers';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { getAllSuppliers, createOffer, getOffer, updateOffer, getOfferById, baseUrlUploads, getAllOffers, updateMultipleOffers } from './../../../service';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import store from '../../../store';
import {
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
  } from '../../../actions/actions';

import './newOffer.scss';

import PopupScript from '../../dialogs/script';
import OfferScript from '../offerScript/';

import SliderSlick from "react-slick";

class NewOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            supplier_id: null,
            redirect: false,
            edit: false,
            offer_id: null,
            elite: false,

            client_type: '',
            client_sub_type: '',
            offer_name: '',
            offer_code: '',
            exp_date: new Date(),
            duration: 1,
            type_product: '',
            price_gas: '',
            price_f0: '',
            price_f1: '',
            price_f2: '',
            price_f3: '',
            price_pcv: '',
            monthly_fee: '',
            gas_description: '',
            ee_description: '',
            dual_description: '',

            // pagamento
            rid: true,
            bollettino: true,
            bonifico: true,
            carta_credito: true,

            // link
            link: '',
            rating: '',
            verde: 'OFF',

            // operations
            allacciamento: false,
            subentro: false,
            '1a_attivazione': false,
            cambio_fornitore: false,
            cambio_con_voltura: false,
            taglio_colonna: false,
            contatore_rimosso: false,
            chiuso_per_morosita: false,

            // filtration
            residente: false,
            non_residente: false,
            uda_o_attivita_commerciale: false,
            condominio: false,
            associazione: false,
            amministrazione: false,

            check_credit_link: '',

            statusPopupScript: false,
            selectedScript: null,

            selectedOffersIds: [],
            offers: [],
            initialState: [],

            procent: false,
            procente_num: 0,
            dispbt_fissa: 0,

            prezzo: '',

            aggiornamento: '',
            comune: '',
            provincia: ''
        }

        this.createOffer = this.createOffer.bind(this);
        this.updateOffer = this.updateOffer.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
        this.openPopupScript = this.openPopupScript.bind(this);
        this.closePopupScript = this.closePopupScript.bind(this);
        this.changeTextScript = this.changeTextScript.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
    }
    
    componentWillMount() {
        const id = this.props.match.params.id;

        const suppliersPromise = getAllSuppliers();
        const offerPromise = (id || id === 0) ? getOfferById(id) : null;

        store.dispatch({ type: OPEN_LOADING, text: 'Loading suppliers...' })

        Promise.all([suppliersPromise, offerPromise]).then(result => {
            store.dispatch({ type: CLOSE_LOADING });
            const [ supplierResp, offerResp ] = result;

            let suppliers = supplierResp.data.suppliers;
            if(!offerResp)
                return this.setState({ suppliers });

            let offer = offerResp.data.offer;

            const selectedSupplier = offer.supplier_id;

            suppliers = suppliers.map(sup => { 
                if(sup.id != selectedSupplier) 
                    return sup;
                
                sup.selected = true;

                return sup;
            })

            offer.duration = +offer.duration;

            store.dispatch({ type: CLOSE_LOADING });

            this.setState({ 
                suppliers, 
                ...offer, 
                offer_id: id,
                supplier_id: selectedSupplier, 
                edit: true
            });

            this.setState({initialState: offer})

         }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't load suppliers` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
         })

         // upload offers for selection
         store.dispatch({ type: CLOSE_LOADING });


         getAllOffers().then(result => {
            const { offers } = result.data;
    
            this.setState({ offers });
            store.dispatch({ type: CLOSE_LOADING });
    
            if(localStorage.getItem("searchText") != null) {
              this.search(localStorage.getItem("searchText"));
            }
    
          }).catch(err => {
              
            setTimeout(() => {
              store.dispatch({ type: CLOSE_LOADING });
            }, 2500)
          })
    
    }

    changeValue(name, value) {
        const state = this.state;

        if(name == 'rating' && value > 100)
            return false;

        state[name] = value;

        switch(name) {
            case 'offer_name':
                state['offer_name_error'] = false;
            break;
            case 'offer_code':
                state['offer_code_error'] = false;
            break;
            case 'client_type':
                state['client_type_error'] = false;
            break;
            case 'type_product':
                state['type_product_error'] = false;
            break;
            case 'sdd_obblig':
                state['sdd_obblig_error'] = false;
            break;

        }

        this.setState(state);
    }

    createOffer() {
        const {
            // exceptions
            suppliers,
            redirect,

            // data of offer
            ...data
        } = this.state;

        const { offers, ...payload } = data;

        createOffer(payload).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Offer been created', open: true});
            this.setState({redirect: true});
        })
    }

    updateOffer() {
        const { offer_id: id, ...data } = this.state;
        const { initialState , selectedOffersIds} = this.state;

        if(this.state.selectedOffersIds.length > 0) {
            let updatedSelectedOffersIds = [...selectedOffersIds, parseInt(id)];
            let changedData = Object.keys(data).reduce((diff, key) => {
                if (initialState[key] === data[key]) return diff
                else if (initialState[key] === undefined) return diff
                return {
                  ...diff,
                  [key]: data[key]
                }
              }, {})

              updateMultipleOffers({ids: updatedSelectedOffersIds, data: changedData}).then(result => {
                store.dispatch({type: OPEN_NOTIFICATION, text: 'Offers been updated', open: true});
                this.setState({ redirect: true });
            }).catch(err => {
                console.log(err);
            })

        } else {

            delete data.initialState;
            delete data.selectedOffersIds;
            delete data.offers;

            updateOffer(id, data).then(result => {
                store.dispatch({type: OPEN_NOTIFICATION, text: 'Offer been updated', open: true});
                this.setState({ redirect: true });
            }).catch(err => {
                console.log(err);
            })
        }
    }

    selectSupplier(id) {
        const suppliers = this.state.suppliers;
        let theSame = false;

        suppliers.map(supplier => {
            if(supplier.id == id) {
                supplier.selected = !supplier.selected;
                theSame = supplier.selected;

                return supplier;
            } else if(supplier.selected) {
                supplier.selected = false;

                return supplier;
            }


            return supplier;
        });

        if(theSame)
            this.setState({ supplier_id: id });
        else
            this.setState({ supplier_id: null });

    }

    checkValidation() {
        const {
            edit,
            offer_name,
            offer_code,
            client_type,
            type_product,
        } = this.state;

        const state = this.state;

        if(!offer_name)
            state['offer_name_error'] = true;

        if(!offer_code)
            state['offer_code_error'] = true;

        if(!client_type)
            state['client_type_error'] = true;

        if(!type_product)
            state['type_product_error'] = true;

        if(
            !offer_name ||
            !offer_code ||
            !client_type ||
            !type_product
        ) return this.setState({...state});


        !edit ? this.createOffer() : this.updateOffer()
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    onChangeBool(name) {
        const data = this.state;

        data[name] = !data[name];

        this.setState(data);
    }

    openPopupScript(selectedScript, text) {
        this.setState({ statusPopupScript: true, selectedScript, selectScriptText: text });
    }

    closePopupScript() {
        this.setState({ statusPopupScript: false, selectScript: null });
    }

    changeTextScript(name, text) {
        const state = this.state;

        state[name] = text;

        this.setState(state);
    }

    onChangeText(name, value) {
        const state = this.state;

        const valueSt = state[name];

        state[name] = valueSt ? '' : value;

        this.setState(state);
    }


    // for multiple offers select

    onSelectionChanged(event, newValue) {
        let updatedSelectedOffers = newValue.map(i => i.id);
        this.setState({ selectedOffersIds: updatedSelectedOffers });
    }

    render() {
        const {
            offer_id,
            suppliers,
            redirect,
            edit,

            supplier_id,
            client_type,
            client_sub_type,
            offer_name,
            offer_code,
            exp_date,
            duration,
            elite,
            type_product,
            price_gas,
            price_f0,
            price_f1,
            price_f2,
            price_f3,
            price_pcv,
            monthly_fee,
            gas_description,
            ee_description,
            dual,

            //pagamento
            rid,
            bollettino,
            bonifico,
            carta_credito,

            // link
            link,

            rating,
            verde,

            // operations
            allacciamento,
            subentro,
            '1a_attivazione': a_attivazione,
            cambio_fornitore,
            cambio_con_voltura,
            taglio_colonna,
            contatore_rimosso,
            chiuso_per_morosita,

            // filtration
            residente,
            non_residente,
            uda_o_attivita_commerciale,
            condominio,
            associazione,
            amministrazione,

            check_credit_link,

            //error
            offer_name_error,
            offer_code_error,
            client_type_error,
            type_product_error,
            sdd_obblig_error,

            statusPopupScript,
            selectedScript,
            selectScriptText,

            // for selection offers 
            offers,

            dispbt_fissa,

            procent,
            procente_num,

            aggiornamento,

            prezzo,

            comune,
            provincia,
            comune_error,
            provincia_error
         } = this.state;

        const {open: loading} = store.getState().loading;

        const { role } = store.getState().auth;

        if(redirect)
            return <Redirect to='/' />

            const settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
              };

        return(
            <form className={loading ? 'hide' : 'new_offer_seciton anim_content'} onSubmit={ (event) => { event.preventDefault(); this.checkValidation()}}>
            {role !== 'admin' && <div className='disable_screen'></div>}
            <Tooltip title='Help'>
                <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
            </Tooltip>
            {
                statusPopupScript && <PopupScript
                    open={statusPopupScript} 
                    selectedScript={selectedScript} 
                    comfirm={this.changeTextScript}
                    handleClose={this.closePopupScript}
                    text={selectScriptText}
                    />
            }
            <div className='supplier_list'>
                        <div className='saparetor'>Suppliers</div>
                        <div className='content' >
                            {/* <GridList cols={0} id='grid-scroll'>

                                {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplier'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                { img 
                                                    ? 
                                                    <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                    </div> 
                                                    : 
                                                    <div className='block_text'>
                                                        {name}
                                                    </div> 
                                                }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </GridList> */}
                            <SliderSlick {...sliderSettings} className='suppliersSlider'>
                            {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplierWrapper'>
                                            <div className='supplier'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                { img 
                                                    ? 
                                                    <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                    </div> 
                                                    : 
                                                    <div className='block_text'>
                                                        {name}
                                                    </div> 
                                                }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </SliderSlick>
                        </div>
                    </div>
                <div className='content'>
                    <div className='saparetor'>Dati di base</div>
                    <div className='fields -limit'>
                        <TextField 
                            onChange={ event => this.changeValue('offer_name', event.target.value)} 
                            value={offer_name} 
                            label='Offer name'
                            variant="outlined"
                            className={offer_name_error ? 'error': ''}
                            />
                         <TextField 
                            onChange={ event => this.changeValue('offer_code', event.target.value)} 
                            value={offer_code} 
                            label='Offer code'
                            variant="outlined"
                            className={offer_code_error ? 'error': ''}
                            />
                        <TextField
                            select
                            label="Prezzo"
                            className='select'
                            onChange={ event => this.changeValue('prezzo', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            value={prezzo}
                            size='medium'
                            >
                            {['Fisso', 'Variabile'].map(option => (
                                <MenuItem required key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField 
                            onChange={ event => this.changeValue('comune', event.target.value)} 
                            value={comune} 
                            label='Comune'
                            variant="outlined"
                            className={comune_error ? 'error': ''}
                        />
                        <TextField 
                            onChange={ event => this.changeValue('provincia', event.target.value)} 
                            value={provincia} 
                            label='Provincia'
                            variant="outlined"
                            className={provincia_error ? 'error': ''}
                        />
                            {/* <div className='space'></div> */}
                         <TextField
                            select
                            label="Tipo cliente"
                            className='select'
                            onChange={ event => this.changeValue('client_type', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size='medium'
                            value={client_type}
                            className={client_type_error ? 'error': ''}
                            >
                            {['resid', 'business'].map(option => (
                                <MenuItem required key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                                select
                                label="Energia"
                                className='select'
                                onChange={ event => this.changeValue('type_product', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='medium'
                                value={type_product}
                                className={type_product_error ? 'error': ''}
                                >
                                {['gas', 'luce', 'dual'].map(option => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                        </TextField>
                         <MuiThemeProvider theme={materialTheme}>
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="picker">
                                <InlineDatePicker
                                    format="dd/MM/yyyy"
                                    label="Data fine validità"
                                    value={exp_date}
                                    onChange={ event => this.changeValue('exp_date', event)}
                                />
                            </div>
                            </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                        <div className='slider_block'>
                            <div className='text -info'>
                                <span>Durata di validità</span>
                                <span>1 mese - 36 mese</span>
                            </div>
                            <div className='text'>
                                {duration} mese
                            </div>
                            <Slider 
                                className='slider'
                                label={duration + ' anno'}
                                value={duration}
                                min={1}
                                max={36}
                                step={1}
                                onChange={ (event, value) => this.changeValue('duration', value)}
                            />
                        </div>
                        <div className='space'></div>
                        <FormControlLabel className={elite ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={elite} onChange={(e) => this.changeValue('elite', e.target.checked)} />} label="Super User" />
                    </div>

                    <div className={type_product ? 'saparetor' : 'hide'}>Pagamento</div>
                    <div className={ type_product ? 'filters' : 'hide' }>

                        <FormControlLabel className={rid ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={rid} onChange={() => { this.onChangeBool('rid') }} />} label="RID" />
                        <FormControlLabel className={bollettino ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={bollettino} onChange={() => { this.onChangeBool('bollettino') }} />} label="Bollettino" />
                        <FormControlLabel className={bonifico ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={bonifico} onChange={() => { this.onChangeBool('bonifico') }} />} label="Bonifico" />

                        {/* <div 
                            className={carta_credito ? 'item select' : 'item no_select'} 
                            onClick={() => { this.onChangeBool('carta_credito') }}
                            >Online con carta credito
                        </div> */}

                    </div>

                    <div className={ type_product ? 'saparetor' : 'hide'}>Operazioni</div>
                    <div className={ type_product ? 'filters' : 'hide' }>
                        <FormControlLabel className={allacciamento ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={allacciamento} onChange={() => { this.onChangeBool('allacciamento') }} />} label="Allacciamento" />
                        <FormControlLabel className={subentro ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={subentro} onChange={() => { this.onChangeBool('subentro') }} />} label="Subentro" />
                        <FormControlLabel className={a_attivazione ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={a_attivazione} onChange={() => { this.onChangeBool('1a_attivazione') }} />} label="1a Attivazione" />
                        <FormControlLabel className={cambio_fornitore ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={cambio_fornitore} onChange={() => { this.onChangeBool('cambio_fornitore') }} />} label="Cambio fornitore" />
                        <FormControlLabel className={cambio_con_voltura ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={cambio_con_voltura} onChange={() => { this.onChangeBool('cambio_con_voltura') }} />} label="Cambio con voltura" />
                        <FormControlLabel className={taglio_colonna ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={taglio_colonna} onChange={() => { this.onChangeBool('taglio_colonna') }} />} label="Taglio colonna" />
                        <FormControlLabel className={contatore_rimosso ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={contatore_rimosso} onChange={() => { this.onChangeBool('contatore_rimosso') }} />} label="Contatore rimosso" />
                        <FormControlLabel className={chiuso_per_morosita ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={chiuso_per_morosita} onChange={() => { this.onChangeBool('chiuso_per_morosita') }} />} label="Allacciamento temporaneo" />
                    </div>

                    <div className={ client_type ? 'saparetor' : 'hide'}>Clente Filtrazione</div>
                    <div className={ client_type ? 'filters' : 'hide' }>
                        <FormControlLabel className={residente ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={residente} onChange={() => { this.onChangeBool('residente') }} />} label="Residente" />
                        <FormControlLabel className={non_residente ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={non_residente} onChange={() => { this.onChangeBool('non_residente') }} />} label="Non residente" />
                        <FormControlLabel className={uda_o_attivita_commerciale ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={uda_o_attivita_commerciale} onChange={() => { this.onChangeBool('uda_o_attivita_commerciale') }} />} label="UDA" />
                        <FormControlLabel className={condominio ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={condominio} onChange={() => { this.onChangeBool('condominio') }} />} label="Condominio" />
                        <FormControlLabel className={amministrazione ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={amministrazione} onChange={() => { this.onChangeBool('amministrazione') }} />} label="Attività commerciale" />
                       
                        {/* <div 
                            className={associazione ? 'item select' : 'item no_select'} 
                            onClick={() => { this.onChangeBool('associazione') }}
                            >Associazione
                        </div>

                        <div 
                            className={amministrazione ? 'item select' : 'item no_select'} 
                            onClick={() => { this.onChangeBool('amministrazione') }}
                            >Amministrazione
                        </div>

                        {client_type == 'business' && <div 
                            className={client_sub_type == 'libero_professionista' ? 'item select' : 'item no_select'} 
                            onClick={() => { this.onChangeText('client_sub_type', 'libero_professionista') }}
                            >Libero professionista
                        </div>} */}

                    </div>
                        
                    <div className={type_product == 'luce' || type_product == 'dual' ? 'saparetor' : 'hide'}>Prezzo luce</div>
                    <div className={type_product == 'luce' || type_product == 'dual' ? 'fields -price_ee' : 'hide'}>
                        <TextField 
                            onChange={ event => this.changeValue('price_f0', event.target.value)} 
                            value={price_f0}
                            type='number'
                            label='F0 (€/kWh)' 
                            variant="outlined"
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_f1', event.target.value)} 
                            value={price_f1}
                            type='number'
                            label='F1 (€/kWh)' 
                            variant="outlined"
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_f2', event.target.value)} 
                            value={price_f2} 
                            type='number'
                            label='F2 (€/kWh)' 
                            variant="outlined"
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_f3', event.target.value)} 
                            value={price_f3}
                            type='number'
                            label='F3 (€/kWh)' 
                            variant="outlined"
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_pcv', event.target.value)} 
                            value={price_pcv}
                            type='number'
                            label='PCV (€/mese)' 
                            variant="outlined"
                         />

                        <TextField 
                            onChange={ event => this.changeValue('dispbt_fissa', event.target.value)} 
                            value={dispbt_fissa}
                            type='number'
                            label='DISPbt fissa (€/POD/anno)'
                            variant="outlined" 
                         />
                    </div>
                    <div className={type_product == 'gas' || type_product == 'dual' ? 'saparetor' : 'hide'}>Prezzo gas</div>
                    <div className={type_product == 'gas' || type_product == 'dual' ? 'fields -price_gas' : 'hide'}>
                        <TextField 
                            onChange={ event => this.changeValue('price_gas', event.target.value)} 
                            value={price_gas}
                            type='number'
                            label='Prezzo gas (€/Smc)' 
                            variant="outlined"
                         />
                         <TextField 
                            onChange={ event => this.changeValue('monthly_fee', event.target.value)} 
                            value={monthly_fee}
                            type='number'
                            label='QVD (€/mese)' 
                            variant="outlined"
                         />
                    </div>

                    <div className={type_product ? 'saparetor' : 'hide'}>Script</div>
                    <div className={type_product ? 'fields -script' : 'hide'}>
                        <OfferScript
                            title={'Luce'}
                            text={ee_description}
                            edit={() => this.openPopupScript('ee_description', ee_description)}
                        />
                        <OfferScript
                            title={'Gas'}
                            text={gas_description}
                            edit={() => this.openPopupScript('gas_description', gas_description)}
                        />
                        <OfferScript
                            title={'Dual'}
                            text={dual}
                            edit={() => this.openPopupScript('dual', dual)}
                        />
                        {/* <TextField
                            className={type_product == 'luce' || type_product == 'dual' ? '' : 'hide'}
                            onChange={ event => this.changeValue('ee_description', event.target.value)} 
                            value={ee_description}
                            label='Luce' 
                            multiline 
                            rows='9'/>
                        <TextField 
                            className={type_product == 'gas' || type_product == 'dual' ? '' : 'hide'} 
                            onChange={ event => this.changeValue('gas_description', event.target.value)} 
                            value={gas_description}
                            label='Gas' 
                            multiline 
                            rows='9'/> */}
                    </div>

                    <div className={type_product ? 'saparetor' : 'hide'}>Link</div>
                    <div className={type_product ? 'fields -limit': 'hide'}>
                        <TextField 
                            onChange={ event => this.changeValue('link', event.target.value)} 
                            value={link} 
                            label='Link for finalization of the Contract'
                            className='col-100'
                            variant="outlined"
                         />
                    </div>

                    <div className={type_product ? 'saparetor' : 'hide'}>Other</div>
                    <div className={type_product ? 'fields -limit': 'hide'}>
                        <TextField
                            select
                            label="Verde"
                            className='select'
                            onChange={ event => this.changeValue('verde', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size='medium'
                            value={verde}
                            variant="outlined"
                            // className={type_product_error ? 'error': ''}
                            >
                            {['ON', 'OFF'].map(option => (
                                <MenuItem key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
                         <TextField 
                            onChange={ event => this.changeValue('rating', event.target.value)} 
                            value={rating} 
                            type='number'
                            label='Ranking'
                            variant="outlined"
                            size='medium'
                            inputProps={{ min: "0", max: "100" }}
                            // className='col-100'
                         />
                         <TextField 
                            onChange={ event => this.changeValue('check_credit_link', event.target.value)} 
                            value={check_credit_link} 
                            label='Check Credit Link'
                            style={{width: '350px'}}
                            variant="outlined"
                            size='medium'
                        />

                        <TextField
                            select
                            label="Aggiornamento offerta"
                            className='select'
                            onChange={ event => this.changeValue('aggiornamento', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            value={aggiornamento}
                            size='medium'
                            >
                            {['Aggiornata', 'In scadenza'].map(option => (
                                <MenuItem required key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    {edit &&
                        <div className="autocompleteWrapper">
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={offers.filter(x => x.id != offer_id)}
                                getOptionLabel={(option) => option.offer_name}
                                onChange={this.onSelectionChanged}
                                onChange={(event, newValue) => this.onSelectionChanged(event, newValue)}
                                sx={{ width: 785 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size='medium'
                                        label="Select multiple offers to edit"
                                        placeholder="Select"
                                    />
                                )}
                            />
                        </div>
                    }
                </div>

                    { role === 'admin' && <div className='submit'>
                        {(!edit) 
                            ? 
                                <Button style={ supplier_id && offer_name && offer_code ? style.createButton : style.createButtonDissabled } className="defaultButton" disabled={ !supplier_id } type='submit'>Create</Button>
                            :
                                <Button style={ supplier_id && offer_name && offer_code ? style.createButton : style.createButtonDissabled } disabled={ !supplier_id } type='submit'>Update</Button>
                        }
                        
                    </div>}
            </form>
        )
    }
}

export default NewOffer;

const style = {
    createButton: {
        width: '200px',
        height: '40px',
        background: '#417BC3',
        color: 'white'
    },

    createButtonDissabled: {
        width: '200px',
        height: '40px',
        background: '#296bbb',
        color: 'white'
    }
}

const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#1460AA',
        },
      },
      MuiPickersDay: {
        day: {
          color: '#1460AA'
        },
        selected: {
          backgroundColor: '#1460AA'
        },
        current: {
          color: '#1460AA'
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#1460AA'
        }
      }
    }
  });

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="offers__slickArrow offers__slick-prev"
        onClick={onClick}
      ><img src='./images/sliderLeftArrow.svg'/></div>
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className='offers__slickArrow offers__slick-next'
        onClick={onClick}
      ><img src='./images/sliderRightArrow.svg'/></div>
    );
  }

  var sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };