import React, { Component } from 'react';
import { 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactQuill from 'react-quill';

import {
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING 
} from '../../../actions/actions';

import { updateTelcoTooltip } from './../../../service';
import store from '../../../store';

class Popup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.changeInput = this.changeInput.bind(this);
        this.updateMessage  = this.updateMessage.bind(this);
    }

    updateMessage() {
        const { id, tooltip, field: nameField, name: nameProp } = this.props;
        const value = this.state.value;

        const newTooltip = { ...tooltip };
        const property = { ...newTooltip[nameField] };
        property[nameProp] = value;

        newTooltip[nameField] = property;

        const changedProp = { ...tooltip[nameField] };

        changedProp[nameProp] = value;
        tooltip[nameField] = changedProp;
        this.props.pushUpdatedTooltip(newTooltip)

        tooltip[nameField] = JSON.stringify(changedProp);

        for(let key in tooltip) {
            const value = tooltip[key];

            if(typeof value === 'object')
                tooltip[key] = JSON.stringify(value);
        }

        store.dispatch({type: OPEN_LOADING, text: 'Updating tooltip', open: true});

        updateTelcoTooltip(id, tooltip).then(result => {
            this.props.handleClose();

            store.dispatch({type: CLOSE_LOADING, open: false});
        }).catch(err => {
            store.dispatch({type: OPEN_LOADING, text: `Can't update the tooltip`, open: true});

            setTimeout(() => {
                store.dispatch({type: CLOSE_LOADING, open: false});
            }, 2000)
        })
    }

    changeInput(value) {
        this.setState({ value });
    }

    render() {

        const { id, field: nameField, name: namePropm, tooltip, open, handleClose } = this.props;

        let value = '';

        if(tooltip[nameField])
            if(tooltip[nameField][namePropm])
                value = tooltip[nameField][namePropm];
            

        return(
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Edit message</DialogTitle>
                <DialogContent style={style.content} className='vissible-item'>
                    <ReactQuill style={{height: '250px'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={this.changeInput}
                        defaultValue={value}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.updateMessage} color="primary">
                    Update
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default Popup;

const style = {
    content: {
        width: '550px',
        height: '400px',
        minheight: '270px',
        overflow: 'visible' 
    }
}
const Editor = {
    modules: {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'formula'],
          
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
          
          
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],
          
            ['clean'] 
        ],
      },
    
      formats: [
        'header',
        'color',
        'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ],
}